import React from 'react';

import * as Sentry from '@sentry/browser';

import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';


let registrationStore = null;
let isUpdateAvailable = null;

let onUpdate = (registration) => {
  console.log('onUpdate... index');
  isUpdateAvailable = registration;
  // registration.waiting.postMessage('skipWaiting');
}

let checkUpdate = () => {
  console.log('checkUpdate pre');
  if (registrationStore !== null) {
    console.log('checkUpdate registrationStore found');
    return registrationStore.update()
  }
}

const updater = (up) => {
  onUpdate = up;
  if (isUpdateAvailable !== null) {
    console.log('updated: calling up - isUpdateAvailable is set...');
    up(isUpdateAvailable)
  }
}

let tmpDsn = (window.location.href.indexOf('localhost') > -1) ? null : "https://268ac056414545aba08379da4d12590c@sentry.io/1765944" ;

console.log("DSN:", tmpDsn);
Sentry.init(
    {
      dsn: tmpDsn,
      relase: 'gixel-client@1.0.0',
    }
  );

ReactDOM.render(<App updater={updater} checkUpdate={checkUpdate}/>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
let config = {
  'registrationHandler': (registration) => {
    console.log('registrationHandler... .');
    console.log(registration);
    registrationStore = registration;
  },
  'onUpdate': (registration) => {
    console.log('onUpdate...');
    onUpdate(registration);
  },
  'onSuccess': (registration) => {
    console.log('onSuccess...');
  },
  'onActivated': (registration) => {
    console.log('onActivated...');
    window.location.reload();
  }
}

serviceWorker.register(config);
