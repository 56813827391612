import React, {useContext, useState, useRef, useEffect, useCallback} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { useTranslation } from 'react-i18next';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FullscreenDialog from './base/FullscreenDialog';
import DialogContent from '@material-ui/core/DialogContent';

import Chip from '@material-ui/core/Chip';

import ClearIcon from '@material-ui/icons/Delete';

import useImage from './base/useImage';

import { Context } from '../Context';
import ImageDraw from './ImageDraw';
import Stepper from './base/Stepper';

import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


import {ACCEPTED_COLOR, REJECTED_COLOR, UNCLASSIFIED_COLOR} from '../Defines';
const useStyles = makeStyles(theme => ({
  regionContainer: {
    // height: '427px',
    // calculated in code due to changing height
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  rejected: {
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: REJECTED_COLOR,
    color: REJECTED_COLOR,
  },
  accepted: {
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: ACCEPTED_COLOR,
    color: ACCEPTED_COLOR,
  },
  unclassified: {
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: UNCLASSIFIED_COLOR,
    color: UNCLASSIFIED_COLOR,
  },
  classificationIcon: {
    margin: theme.spacing(0.5),
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'calc(100vw - 275px) auto',
    paddingTop: theme.spacing(0.5),
  },
  flexImage: {
  },
  flexButtons: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
  },
  flexGrow: {
    flexGrow: 1,
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonAcceptActive: {
    backgroundColor: ACCEPTED_COLOR + '! important', // avoid hover effects on active
    borderBottomRightRadius:  '50px',
    borderTopRightRadius: '50px',
    width: '50%',
  },
  buttonRejectActive: {
    backgroundColor: REJECTED_COLOR + '! important', // avoid hover effects on active
    borderBottomRightRadius:  '50px',
    borderTopRightRadius: '50px',
    width: '50%',
  },
  buttonAccept: {
    color: ACCEPTED_COLOR,
    backgroundColor: UNCLASSIFIED_COLOR,
    borderBottomRightRadius:  '50px',
    borderTopRightRadius: '50px',
    width: '50%',
  },
  buttonReject: {
    color: REJECTED_COLOR,
    backgroundColor: UNCLASSIFIED_COLOR,
    borderBottomRightRadius:  '50px',
    borderTopRightRadius: '50px',
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1.5),
  },
  chip: {
    marginTop: theme.spacing(1.5),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));



function RegionDetail(props) {
  const { t } = useTranslation();
  const context = useContext(Context);
  const [size, setSize] = useState({width: 640, height: 480, scale: {x: 1, y: 1}});
  const [resizeCounter, setResizeCounter] = useState(0);

  const classes = useStyles();
  const drawingRef = useRef();


  let regionTaskMap = [];
  _.each(props.image.regions, (r, rIdx) => {
    _.each(r.tasks, (t, tIdx) => {
      regionTaskMap.push({regionIdx: rIdx, taskIdx: tIdx});
    })
  })

  let src = "";
  if (props.image.imageData && props.image.imageType) {
    src = `data:image/${props.image.imageType};base64,${props.image.imageData}`;
  } else {
    src = props.image.imageUrl;
  }

  const [currentImage] = useImage(src);

  let region = props.image.regions[regionTaskMap[props.regionTaskDetailIdx].regionIdx];

  let crop = {
    x: region.left,
    y: region.top,
    width: region.right-region.left,
    height: region.bottom-region.top,
  };

  let regionTaskCount = _.size(regionTaskMap);

  function handleNext() {
    props.setRegionTaskDetailIdx(Math.min(regionTaskCount - 1, props.regionTaskDetailIdx + 1));
  }

  function handleBack() {
    props.setRegionTaskDetailIdx(Math.max(0, props.regionTaskDetailIdx - 1));
  }

  let taskObj = {};
  _.each(context.taskDocs.docs, (taskDoc) => {
    taskObj[taskDoc.id] = taskDoc.data();
  });

  function autoSaveImageMarking(task, image) {
    if (task && task.taskId && task.taskImageId && image && context.selectedCustomerId) {
      console.log("AUTOSAVING..");
      firebase.firestore().collection('customerdata').doc(context.selectedCustomerId)
      .collection('tasks').doc(task.taskId).collection('taskimages').doc(task.taskImageId)
      .update({
        markImage: image,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(()=>{
        console.log('saved...');
      });
    }
  }

  function handleTaskImageUopdate(task, classification){
    console.log('handle task update');
    console.log('classification', classification);
    console.log('task', task);
    firebase.firestore().collection('customerdata').doc(context.selectedCustomerId)
    .collection('tasks').doc(task.taskId).collection('taskimages').doc(task.taskImageId)
    .update(
      {
        classification: classification,
        classificationSource: 'operator',
        classificationTime: firebase.firestore.FieldValue.serverTimestamp(),
        classifications: firebase.firestore.FieldValue.arrayUnion(
          {
            classification: classification,
            classificationSource: 'operator',
            localClassificationTime: new Date(),
          }
        ),
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
    ).then(()=>{
      console.log('saved...');
    });
  }
  let task = props.image.regions[regionTaskMap[props.regionTaskDetailIdx].regionIdx].tasks[regionTaskMap[props.regionTaskDetailIdx].taskIdx];


  // we use the effect to get our resizerCallback called again..
  useEffect(() => {
    function resizer () {
      setResizeCounter(c => c + 1);
    }
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  }, []);

  const resizerCallback = useCallback((node) => {
    if (node !== null) {
      let scale = Math.min(
                            node.offsetWidth / crop.width,
                            node.offsetHeight / crop.height,
                          );
      setSize({width: crop.width*scale, height:crop.height*scale, scale: {x: scale, y:scale}})
    }
  //eslint-disable-next-line
  }, [resizeCounter, crop.width, crop.height]); // NOTE: we use the resizecounter here to recalculate whenever a resize event happens..




  let classificationClass = classes.unclassified;
  switch (task.classification) {
    case 'rejected':
      classificationClass = classes.rejected;
      break;
    case 'accepted':
      classificationClass = classes.accepted;
      break;
    default:
      classificationClass = classes.unclassified;
      break;
  }

  console.log(task)

  let styleHeight = {
    height: 'calc(100vh - ' + (regionTaskCount > 1 ? 72 : 20) + 'px)'
  };
  return  <FullscreenDialog handleClose={props.handleClose} useTransition={false}>
            <DialogContent>
            <div className={classes.gridContainer}>
              <div className={classes.flexImage}>
                <div style={styleHeight} ref={resizerCallback} className={classes.regionContainer}>
                  <div className={classificationClass}>
                    <ImageDraw ref={drawingRef} isDrawing={!_.isNil(task.taskImageId)} autoSaveDrawing={(image) => {autoSaveImageMarking(task, image)}} size={size} crop={crop} image={currentImage} markImage={task.markImage ? task.markImage : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='} taskImageId={task.taskImageId}/>
                  </div>
                </div>
              </div>
              <div className={classes.flexButtons}>
                <div className={classes.margin}>
                  <Typography>Task</Typography>
                  <Chip className={classes.chip}  label={_.get(taskObj, regionTaskMap[props.regionTaskDetailIdx].taskIdx + '.name')}/>
                </div>
                <div className={classes.margin}>
                  <Typography>Status</Typography>
                  <Chip className={clsx(classes.chip, classificationClass)} variant="outlined" label={task.classification ? t(task.classification) : t('unclassified')}/>
                </div>
                { context.networkSettings.isEnabled &&
                  <div className={classes.margin}>
                    <Button key={'clear'} onClick={()=>{drawingRef.current.clearDrawingImage();} }>
                      <ClearIcon className={classes.leftIcon} />
                      {t('clear marking')}
                    </Button>
                  </div>
                }
                <div className={classes.flexGrow}>
                </div>
                { context.networkSettings.isEnabled &&
                  <React.Fragment>
                    <div className={classes.buttonContainer}>
                      <Button style={_.isNil(task.taskImageId) ? {color: 'lightgrey'} : {}} className={task.classification === 'accepted' ? classes.buttonAcceptActive : classes.buttonAccept } key={'accept'} disabled={_.isNil(task.taskImageId) || task.classification === 'accepted'} onClick={()=>{handleTaskImageUopdate(task, 'accepted')} }>{t('accept')}</Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button style={_.isNil(task.taskImageId) ? {color: 'lightgrey'} : {}} className={task.classification === 'rejected' ? classes.buttonRejectActive : classes.buttonReject } key={'reject'} disabled={_.isNil(task.taskImageId) || task.classification === 'rejected'} onClick={()=>{handleTaskImageUopdate(task, 'rejected')} }>{t('reject')}</Button>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>

            </DialogContent>
            { regionTaskCount > 1 &&
              <Stepper regionCount={regionTaskCount} activeRegionIdx={props.regionTaskDetailIdx} handleNext={handleNext} handleBack={handleBack} />
            }
          </FullscreenDialog>;

}

export default RegionDetail;
