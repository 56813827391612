import React, {useContext, useState, useEffect} from 'react';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import isCidr from 'is-cidr';
import isIp from 'is-ip';


import FullscreenDialog from './base/FullscreenDialog';
import Keyboard from './base/Keyboard';

import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import { NETWORKSETTINGSINIT } from '../Defines';

const useStyles = makeStyles(theme => ({
  networkSettingsGroup: {
    paddingTop: theme.spacing(4),
  },
}));


function NetworkSettings(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [networkSettings, setNetworkSettings] = React.useState(props.networkSettingsInit || NETWORKSETTINGSINIT );

  const keyboardRef = React.useRef();

  const [currentFocus, setCurrentFocus] = React.useState('email');


  console.log("Current network settings:")
  console.log(networkSettings)
  const handleSave = () => {
    console.log('emitting setNetworkSettings')
    if (networkSettings.isEnabled === false) {
      console.log({isEnabled: false});
      props.socket.emit('setNetworkSettings', {isEnabled: false});
    } else {
      console.log(networkSettings);

      props.socket.emit('setNetworkSettings', networkSettings);
    }

    props.handleClose();
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleSettingsChange = (key, type, value) => {
    console.log("handleSettingsChange")
    if (type === 'string') {
      value.persist(); // as we use the vent async in setNetworkSettings we need to persist the event...
    }
    setNetworkSettings((prevSettings) => {
      console.log(key);
      console.log(type);
      console.log(value);
      let tmpSettings = _.cloneDeep(prevSettings);
      switch(type){
        case 'boolean':
        console.log('bool')
          _.set(tmpSettings, key, !_.get(prevSettings, key));
          break;
        case 'string':
          _.set(tmpSettings, key, value.target.value);
          break;
        default:
          _.set(tmpSettings, key, value);
          break;
      }
      console.log(tmpSettings)
      return tmpSettings;

    })
  }

  const handleKeyboardChange = (input) => {
    let tmp = _.cloneDeep(networkSettings);
    _.set(tmp, currentFocus, input);
    setNetworkSettings(tmp);
  }

  const handleKeyPress = (button) => {
    switch(button) {
      case '{enter}':
        break;
      default:
        // do nothing.. we handle changed in the onChange handler
        break;
    }
  }

  let error = {};
  if (networkSettings.isEnabled === true && !networkSettings.useDHCP && !isCidr.v4(_.get(networkSettings, 'ipv4.ip'))) {
    _.set(error, 'ipv4.ip', true);
  }
  if (networkSettings.isEnabled === true && !networkSettings.useDHCP && _.get(networkSettings, 'ipv4.gateway') !== "" &&  !isIp.v4(_.get(networkSettings, 'ipv4.gateway'))) {
    _.set(error, 'ipv4.gateway', true);
  }

  if (networkSettings.isEnabled === true && _.get(networkSettings, 'dns.primary') !== "" && !isIp.v4(_.get(networkSettings, 'dns.primary'))) {
    _.set(error, 'dns.primary', true);
  }

  if (networkSettings.isEnabled === true && _.get(networkSettings, 'dns.secondary') !== "" && !isIp.v4(_.get(networkSettings, 'dns.secondary'))) {
    _.set(error, 'dns.secondary', true);
  }

  return  (
        <Dialog open={true} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
          <DialogTitle>IPv4 Settings</DialogTitle>
          <DialogContent style={{minWidth: '960px'}} >
            <div className={classes.networkSettingsGroup}>
              <FormControlLabel control={
                  <Switch color="primary" checked={networkSettings.isEnabled} onChange={_.partial(handleSettingsChange, 'isEnabled', 'boolean') } />
                } label={t("Enable Network")} />
            </div>
            {
              networkSettings.isEnabled &&
              <React.Fragment>
                <div className={classes.networkSettingsGroup}>
                  <FormControlLabel control={
                      <Switch color="primary" checked={networkSettings.useDHCP} onChange={_.partial(handleSettingsChange, 'useDHCP', 'boolean') } />
                    } label={t("Use DHCP")} />

                  <TextField
                    autoFocus
                    error={_.get(error, 'ipv4.ip')}
                    disabled={networkSettings.useDHCP}
                    onFocus={()=>setCurrentFocus('ipv4.ip')}
                    onChange={_.partial(handleSettingsChange, 'ipv4.ip', 'string')}
                    value={_.get(networkSettings, 'ipv4.ip')}
                    margin="dense"
                    id="ip"
                    label={t('IP')}
                    fullWidth
                    helperText="eg. 192.168.1.55/24"
                  />
                  <TextField
                    error={_.get(error, 'ipv4.gateway')}
                    disabled={networkSettings.useDHCP}
                    onFocus={()=>setCurrentFocus('ipv4.gateway')}
                    onChange={_.partial(handleSettingsChange, 'ipv4.gateway', 'string')}
                    value={_.get(networkSettings, 'ipv4.gateway')}
                    margin="dense"
                    id="gateway"
                    label={t('Gateway')}
                    fullWidth
                    helperText="eg. 192.168.1.1"
                  />
                </div>

                <div className={classes.networkSettingsGroup}>
                  <TextField
                    error={_.get(error, 'dns.primary')}
                    onFocus={()=>setCurrentFocus('dns.primary')}
                    onChange={_.partial(handleSettingsChange, 'dns.primary', 'string')}
                    value={_.get(networkSettings, 'dns.primary')}
                    margin="dense"
                    id="dns.primary"
                    label={t('Primary DNS')}
                    fullWidth
                    helperText="eg. 1.1.1.1. This will override DHCP for DNS if used."
                  />
                  <TextField
                    error={_.get(error, 'dns.secondary')}
                    onFocus={()=>setCurrentFocus('dns.secondary')}
                    onChange={_.partial(handleSettingsChange, 'dns.secondary', 'string')}
                    value={_.get(networkSettings, 'dns.secondary')}
                    margin="dense"
                    id="dns.secondary"
                    label={t('Secondary DNS')}
                    fullWidth
                    helperText="eg. 8.8.8.8. This will override DHCP for DNS if used."
                  />
                </div>
                <Keyboard
                  keyboardRef={keyboardRef}
                  onChange={handleKeyboardChange}
                  onKeyPress={handleKeyPress}
                  inputName={currentFocus}
                  layout={{
                    default: t('keyboard_layout_ip', {returnObjects: true}),
                  }}
                  />
              </React.Fragment>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {props.cancelText ? props.cancelText : t('Cancel')}
            </Button>
            <Button onClick={handleSave} color="primary" disabled={_.size(error) > 0}>
              {props.saveText ? props.saveText : t('Save')}
            </Button>
          </DialogActions>

        </Dialog>
      );

}

export default NetworkSettings;
